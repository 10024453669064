<!-- 首頁:風控流程圖 -->
<template>
  <div class="graphic">
    <div class="main-block">
      <div class="effect purple">
        <div class="container">
          <div v-for="i in 4" :key="i" class="dashing"><i /></div>
        </div>
      </div>
      <div class="block-row">
        <div class="classify">
          <div class="circle purple">
            <v-icon icon="account_tree" color="#fff" />
          </div>
          <component :is='deviceView.desktop ? "h5" : "h4"' class="bold" :class="deviceView.desktop ? 'my-6' : 'my-3'">{{ $t(Words.HomeRiskCaption1) }}</component>
        </div>
        <div class="group purple">
          <div class="group-body" :class="{flatten: deviceView.desktop}">
            <div class="feature">
              <v-icon icon="compare" color="accent" :size="deviceView.mobile ? 'x-small' : ''" />
              <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                {{ $t(Words.HomeRiskFeature1) }}
              </component>
            </div>
            <div class="feature">
              <v-icon icon="policy" color="accent" :size="deviceView.mobile ? 'x-small' : ''"  />
              <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                {{ $t(Words.HomeRiskFeature2) }}
              </component>
            </div>
            <div class="feature">
              <v-icon icon="area_chart" color="accent" :size="deviceView.mobile ? 'x-small' : ''"  />
              <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                {{ $t(Words.HomeRiskFeature3) }}
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-block">
      <div class="effect">
        <div class="container">
          <div v-for="i in 4" :key="i" class="dashing"><i /></div>
        </div>
      </div>
      <div class="block-row">
        <div :class="{'d-flex': deviceView.desktop}" style="gap:48px;">
          <div :class="{'flex-grow-1': deviceView.desktop}">
            <div class="classify">
              <div class="circle blue">
                <v-icon icon="villa" color="#fff" />
              </div>
              <component :is='deviceView.desktop ? "h5" : "h4"' class="bold" :class="deviceView.desktop ? 'my-6' : 'my-3'">{{ $t(Words.HomeRiskCaption2) }}</component>
            </div>
            <div class="group">
              <div class="group-body">
                <div class="feature">
                  <v-icon icon="verified" color="primary" :size="deviceView.mobile ? 'x-small' : ''" />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature4) }}
                  </component>
                </div>
                <div class="feature">
                  <v-icon icon="visibility" color="primary" :size="deviceView.mobile ? 'x-small' : ''"  />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature5) }}
                  </component>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'flex-grow-1': deviceView.desktop, 'mt-12': deviceView.mobile}">
            <div class="classify">
              <div class="circle blue">
                <v-icon icon="widgets" color="#fff" />
              </div>
              <component :is='deviceView.desktop ? "h5" : "h4"' class="bold" :class="deviceView.desktop ? 'my-6' : 'my-3'">{{ $t(Words.HomeRiskCaption3) }}</component>
            </div>
            <div class="group">
              <div class="group-body">
                <div class="feature">
                  <v-icon icon="monitor_heart" color="primary" :size="deviceView.mobile ? 'x-small' : ''" />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature6) }}
                  </component>
                </div>
                <div class="feature">
                  <v-icon icon="lock" color="primary" :size="deviceView.mobile ? 'x-small' : ''"  />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature7) }}
                  </component>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'flex-grow-1': deviceView.desktop, 'mt-12': deviceView.mobile}">
            <div class="classify">
              <div class="circle blue">
                <v-icon icon="radio_button_checked" color="#fff" />
              </div>
              <component :is='deviceView.desktop ? "h5" : "h4"' class="bold" :class="deviceView.desktop ? 'my-6' : 'my-3'">{{ $t(Words.HomeRiskCaption4) }}</component>
            </div>
            <div class="group">
              <div class="group-body">
                <div class="feature">
                  <v-icon icon="sd_card_alert" color="primary" :size="deviceView.mobile ? 'x-small' : ''" />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature8) }}
                  </component>
                </div>
                <div class="feature">
                  <v-icon icon="insert_chart" color="primary" :size="deviceView.mobile ? 'x-small' : ''"  />
                  <component :is='deviceView.desktop ? "h6" : "div"' class="text" :class="deviceView.mobile ? 'font-body' : ''">
                    {{ $t(Words.HomeRiskFeature9) }}
                  </component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const deviceView = useDeviceView()
const { english: enMode } = useViewMode()

</script>

<style lang="stylus" scoped>
.main-block
  position relative
  +.main-block
    margin-top 60px
.block-row
  position relative
  padding 0 24px
  .classify
    text-align center
  .circle
    position relative
    width 60px
    height 60px
    border-radius 50%
    margin auto
    +under(md)
      width 48px
      height 48px
    i
      position absolute
      left 50%
      top 50%
      transform translate(-50%, -50%)
  .circle
    &.blue
      background linear-gradient(145.06deg, #1469CD 0%, #0B548F 100%)      
    &.purple
      background linear-gradient(168.22deg, #B71D67 0%, #801B4B 100%)
  .group-body
    width 100%
    border 1px solid #1469CD    
    border-image-slice 1
    border-image-source linear-gradient(145.06deg, #1469CD, #0B548F)
  .feature
    flex 1 1 0
    padding 24px
    background #fff
    display flex
    align-items center
    +over(md)
      height 100px
    +under(md)
      padding 16px 12px
    .text
      +over(md)
        margin-left 16px
      +under(md)
        margin-left 12px
    +.feature
      border-top 1px solid
      border-image-slice 1
      border-image-source linear-gradient(145.06deg, #1469CD, #0B548F)
  // 扁平型態
  .group-body.flatten
    display flex
    .feature
      height 100px
      +.feature
        border-top none
        border-left 1px solid #1469CD
  // 改顏色
  .group.purple
    .group-body, .feature + .feature
      border-image-source linear-gradient(168.22deg, #B71D67 0%, #801B4B 100%) 

// 線條動畫(預設藍版)
.effect
  position absolute
  width 100%
  height 100%
  padding 30px 0 100px
  +under(sm)
    padding 24px 0 56px
  .container
    position relative
    width 100%
    height 100%
    overflow hidden
  .dashing
    position absolute
    width 100%
    height 100%
    &:nth-of-type(2)
      transform rotate(270deg)
      height 2px
      right 0
      top 0
      transform-origin 100% 50%
    &:nth-of-type(3)
      transform rotate(180deg)
    &:nth-of-type(4)
      transform rotate(90deg)
      transform-origin 0% 50%
      height 2px
    +under(sm)
      &:nth-of-type(2), &:nth-of-type(4)
        width 200%
    i
      display block
      position absolute
      left 0
      top 0
      width 200%
      border-bottom 1px dashed
      border-color #227BD5
      animation slideDash 8s infinite linear
.effect.purple
  padding 30px 0 50px
  +under(sm)
    padding 24px 0 56px
  .dashing
    i
      border-color #B71D67


@keyframes slideDash {
  from { transform: translateX(-200px); }
  to   { transform: translateX(0%); }
}
  
</style>