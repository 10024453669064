<!-- 主視覺 -->
<template>
  <div class="topic-view">
    <swiper :autoplay="{ delay: 4000 }" class="cover-slider">
      <swiper-slide
        v-for="(item, index) in 3"
        :key="index">
        <div class="bg-layout">
          <div class="slogan">
            <div class="web-content">
              <h5 class="mb-4">{{ $t(Words.HomeCoverSlogan_1) }}</h5>
              <div class="topic">
                <template v-if="enMode">
                  <span class="colored">HFM Management Firm of the Year.</span> <br class="break">UG delivers excess returns.
                </template>
                <template v-else>
                  HFM 大獎 <span class="colored">最佳基金管理公司</span><span v-if="deviceView.mobile">，</span><br class="break">動盪中創造超額報酬
                </template>
              </div>
              <NuxtLink v-if="deviceView.desktop" :to="localePath({ name: 'awards' })">
                <v-btn variant="flat" color="primary" size="x-large">{{ $t(Words.HomeCoverBtn) }}</v-btn>
              </NuxtLink>
            </div>
          </div>
          <div class="cover-box">
            <div class="cover" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts" setup>
const { english: enMode } = useViewMode()
const deviceView = useDeviceView()
const localePath = useLocalePath()

</script>

<style lang="stylus" scoped>
.topic-view
  position relative
  .slogan
    position relative
    padding 120px 0
    box-sizing border-box
    z-index 10
    +under(md)
      text-align center
      padding 48px 0 0
    .colored
      color $primary
  .topic
    font-size 48px
    line-height 67.2px
    font-weight bold
    letter-spacing -1%
    +over(md)
      margin-bottom 48px
    html[lang="en-US"] &
      font-size 43.6px
    +under(md)
      font-size 32px
      line-height 48px
      letter-spacing 0.08px
  br.break
    +under(md)
      display none

// 圖片加工
.cover-slider
  .swiper-slide:nth-child(1)
    .bg-layout
      background #D9D5CD
    .cover-box:after
      background linear-gradient(90deg, #D9D5CD -0.81%, rgba(217, 213, 205, 0.8) 9.27%, rgba(217, 213, 205, 0.2) 19.35%, rgba(217, 213, 205, 0) 100%)
      +under(md)
        background linear-gradient(180deg, #D9D5CD 0%, rgba(217, 213, 205, 0.85) 15%, rgba(217, 213, 205, 0) 99.97%)
    .cover
      background-image url(@/assets/images/cover-slide/1.png)
  .swiper-slide:nth-child(2)
    .bg-layout
      background #CBE0EE
    .cover-box:after
      background linear-gradient(90deg, #CBE0EE -0.81%, rgba(203, 224, 238, 0.796078) 9.27%, rgba(203, 224, 238, 0.2) 19.35%, rgba(203, 224, 238, 0) 100%)
      +under(md)
        background linear-gradient(180deg, #CBE0EE 0%, rgba(203, 224, 238, 0.85) 15%, rgba(203, 224, 238, 0) 99.97%)
    .cover
      background-image url(@/assets/images/cover-slide/2.png)
  .swiper-slide:nth-child(3)
    .bg-layout
      background #E9E7D3
    .cover-box:after
      background linear-gradient(90deg, #E9E7D3 -0.81%, rgba(233, 231, 211, 0.8) 9.27%, rgba(233, 231, 211, 0.2) 19.35%, rgba(233, 231, 211, 0) 100%)
      +under(md)
        background linear-gradient(180deg, #E9E7D3 0%, rgba(233, 231, 211, 0.85) 15%, rgba(233, 231, 211, 0) 99.97%)
    .cover
      background-image url(@/assets/images/cover-slide/3.png)
  .cover-box
    position absolute
    right 0
    top 0
    width 645px
    height 100%
    +under(md)
      position relative
      width 100%
      padding-bottom 81.8%
    &:after
      position absolute
      content ''
      left -1px
      top 0
      width 100%
      height 100%
    .cover
      width 100%
      height 100%
      background-position center center
      background-size cover
      background-repeat no-repeat
      +under(md)
        position absolute
</style>